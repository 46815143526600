import {Box} from "grommet";
import React from "react";


export function SideBar(props: any): JSX.Element {
    const { width } = props;
    return (<Box width={width}
                 height='100vh'
                 background='light-2'
                 elevation='small'
                 align='center'
                 justify='start'
                 {...props} />);
}