import {Site, TheSite, ThePage} from "../models/Models";

const BASE_URL = 'https://kylmakalja.eu/api';

function getOnfulfilled(response: Response) {
    if (!response.ok) {
        throw new Error('HTTP: ' + response.status);
    }

    return response.json();
}

export function fetchSites(): Promise<Site[]> {
    return fetch(`${BASE_URL}/site`)
        .then(getOnfulfilled);
}

export function fetchTheSite(slug: string): Promise<TheSite> {
    return fetch(`${BASE_URL}/site/${slug}`)
        .then(getOnfulfilled);
}

export function fetchThePage(slug: string): Promise<ThePage> {
    return fetch(`${BASE_URL}/page/${slug}`)
        .then(getOnfulfilled);
}