import {Box} from "grommet";
import React from "react";


export interface IErrorProps {
    message: string
}

export function ErrorComponent(props: IErrorProps) {
    return <Box flex align='center' justify='center'>
        <p>Error: <span>{props.message}</span></p>
    </Box>;
}