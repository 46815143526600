import {Link} from "react-router-dom";
import {IconProps, Link as GrommetLink} from "grommet-icons/icons";


export interface IStyledLinksProps {
    path: string
    text: string
    color?: string
    iconProps?: IconProps
}

export function StyledLink(props: IStyledLinksProps) {
    const { path, text, color, iconProps } = props;

    return (<Link style={{ color: color ? color : '#424040', display: 'flex', alignItems: 'center', paddingBottom: '0.65rem'}} to={path}>
                <GrommetLink {...iconProps} />
                <span style={{ marginLeft: '0.5rem'}}>{text}</span>
            </Link>);
}