import React from "react";
import {fetchSites} from "../services/api";
import {ErrorComponent} from "./ErrorComponent";
import {LoadingComponent} from "./LoadingComponent";
import {StyledLink} from "./StyledLink";
import {Site} from "../models/Models";
import {useQuery} from "react-query";
import {STALE_TIME} from "../constants/config";


export function SideBarContent() {
    const { isLoading, error, data: sites } = useQuery<Site[], Error>('sites', () => fetchSites(), { staleTime: STALE_TIME });


    if(error) {
        return <ErrorComponent message={error.message} />;
    }

    if(isLoading) {
        return <LoadingComponent />;
    }

    return (<div>
        {sites && <div style={{ margin: '2rem 0'}}>
            {sites.map(sites => <div key={sites.id}>
                <StyledLink path={'/site/' + sites.slug} text={sites.title} color='#2f2b2b' iconProps={{ color: '#2f2b2b', size: 'medium'}} />
            </div>)}
        </div>}
    </div>);
}