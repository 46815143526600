import React from "react";
import {Page, PageContent} from "grommet";
import {fetchSites} from "../services/api";
import {useQuery} from "react-query";
import {Site} from "../models/Models";
import {LoadingComponent} from "../components/LoadingComponent";
import {ErrorComponent} from "../components/ErrorComponent";
import {PageHeaderComponent} from "../components/PageHeaderComponent";
import {StyledLink} from "../components/StyledLink";
import {STALE_TIME} from "../constants/config";


export function HomeComponent() {
    const { isLoading, error, data: sites } = useQuery<Site[], Error>('sites', () => fetchSites(), { staleTime: STALE_TIME });

    if(error) {
        return <ErrorComponent message={error.message} />;
    }

    if(isLoading) {
        return <LoadingComponent />;
    }

    return <Page flex align='center'>
                <PageHeaderComponent title='Sites:' />
                <PageContent flex align='center' background="light-3" style={{ minHeight: '80vh'}}>
                {sites && <div style={{ margin: '2rem 0', paddingLeft: '1 rem', width: '100%' }}>
                    {sites.map(site => <div key={site.id}>
                        <StyledLink path={'/site/' + site.slug} text={site.title} color='#2f2b2b' iconProps={{ color: '#2f2b2b', size: 'medium'}} />
                    </div>)}
                </div>}
                </PageContent>
            </Page>;
}