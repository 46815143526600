import React from "react";
import {Page, PageContent} from "grommet";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {fetchTheSite} from "../services/api";
import {TheSite} from "../models/Models";
import {NotFoundComponent} from "./NotFoundComponent";
import {LoadingComponent} from "../components/LoadingComponent";
import {ErrorComponent} from "../components/ErrorComponent";
import {PageHeaderComponent} from "../components/PageHeaderComponent";
import {StyledLink} from "../components/StyledLink";
import {STALE_TIME} from "../constants/config";

export function TheSiteComponent() {
    const { site } = useParams();
    const { isLoading, error, data } = useQuery<TheSite, Error>(`site-${site}`, () => fetchTheSite(site!), { staleTime: STALE_TIME });

    if(error) {
        return <ErrorComponent message={error.message} />;
    }

    if(isLoading) {
        return <LoadingComponent />;
    }

    if(!data) return <NotFoundComponent />;

    return (<Page flex align='center' >
             <PageHeaderComponent title={data.site.title} />
             <PageContent background="light-3" align='center' style={{ minHeight: '80vh'}}>
                <p dangerouslySetInnerHTML={{__html: data.site.text }}></p>
                <ul style={{ listStyle: 'none', padding: '1rem', width: '100%'}}>
                    {data.pages && data.pages.map(page => <li key={page.id} style={{ padding: '0', margin: '0'}}>
                        <StyledLink path={'/page/' + page.slug} text={page.title} color='#2f2b2b' iconProps={{ color: '#2f2b2b', size: 'medium'}} />
                    </li>)}
                </ul>
            </PageContent>
    </Page>);
}