import {PageHeader} from "grommet";
import React from "react";


export interface IPageHeaderComponentProps {
    title: string
}

export function PageHeaderComponent(props: IPageHeaderComponentProps) {
    return  <div style={{ width: '100%', borderBottom: '1px solid #666', margin: '0.25rem', textAlign: 'center'}} >
                <PageHeader alignSelf="stretch" title={props.title} />
            </div>;
}