import {Box} from "grommet";
import React from "react";


export function AppBar(props: any): JSX.Element {
    return (<Box tag='header'
                 direction='row'
                 align='center'
                 justify='between'
                 background='brand'
                 pad={{ left: 'medium', right: 'small', vertical: 'small' }}
                 elevation='medium'
                 style={{ zIndex: '1' }}
                 {...props} />);
};