import React from 'react';
import {BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query'
import LayoutComponent from "./pages/LayoutComponent";
import {HomeComponent} from "./pages/HomeComponent";
import {AboutComponent} from "./pages/AboutComponent";
import {TheSiteComponent} from "./pages/TheSiteComponent";
import {NotFoundComponent} from "./pages/NotFoundComponent";
import {ThePageComponent} from "./pages/ThePageComponent";

const queryClient = new QueryClient();

function App() {

  return (
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LayoutComponent />}>
                    <Route index element={<HomeComponent />} />
                    <Route path="site/:site" element={<TheSiteComponent />} />
                    <Route path="page/:page" element={<ThePageComponent />} />
                    <Route path="about" element={<AboutComponent />} />
                    <Route path="*" element={<NotFoundComponent />} />
                </Route>
            </Routes>
        </BrowserRouter>
     </QueryClientProvider>
  );
}

export default App;
