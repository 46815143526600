import React from "react";
import {Card, CardBody, CardHeader, Image, Page, PageContent} from "grommet";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {ThePage} from "../models/Models";
import {fetchThePage} from "../services/api";
import {NotFoundComponent} from "./NotFoundComponent";
import {LoadingComponent} from "../components/LoadingComponent";
import {ErrorComponent} from "../components/ErrorComponent";
import {PageHeaderComponent} from "../components/PageHeaderComponent";
import {STALE_TIME} from "../constants/config";

export function ThePageComponent() {
    const { page } = useParams();
    const { isLoading, error, data } = useQuery<ThePage, Error>(`page-${page}`, () => fetchThePage(page!), { staleTime: STALE_TIME });

    if(error) {
        return <ErrorComponent message={error.message} />;
    }

    if(isLoading) {
        return <LoadingComponent />;
    }

    if(!data) return <NotFoundComponent />;

    return (<Page flex align='center' justify='center'>
                <PageHeaderComponent title={data.page.title} />
                <PageContent background="light-3" align='center' style={{ minHeight: '80vh'}}>
                     <p dangerouslySetInnerHTML={{__html: data.page.text }} ></p>
                     <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {data.images && data.images.map(image =>
                            <Card width="medium" key={image.id} style={{ margin: '1rem 0', background: '#fff'}}>
                                <CardHeader pad="small">
                                   <h4 style={{ fontSize: '2rem', padding: '.5rem 0', margin: '0', textTransform: 'capitalize' }}>{image.title}</h4>
                                </CardHeader>
                                <CardBody pad="small">
                                    <Image fit="cover"  src={image.image_path} alt={image.title} title={image.title} style={{ borderRadius: '12px'}} />
                                </CardBody>
                            </Card>)}
                     </div>
                </PageContent>
            </Page>);
}