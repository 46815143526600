import {AppBar} from "../components/AppBar";
import {Box,
        Button,
        Collapsible,
        Grommet,
        Heading,
        Layer,
        ResponsiveContext,
        Footer,
        Page,
        PageContent} from "grommet";
import {FormClose, Notification} from "grommet-icons";
import {SideBar} from "../components/SideBar";
import React, {useState } from "react";
import {Outlet} from "react-router-dom";
import {StyledLink} from "../components/StyledLink";
import {SideBarContent} from "../components/SideBarContent";

const theme = {
    global: {
        colors: {
            brand: '#228BE6'
        },
        font: {
            family: 'Roboto',
            size: '18px',
            height: '20px',
        },
    },
};



function LayoutComponent() {
    const [ showSidebar, setShowSidebar ] = useState(false);

    return (
        <Grommet theme={theme} full>
        <ResponsiveContext.Consumer>
        {size => (
            <><AppBar>
                <Heading level='3' margin='none'>
                    <StyledLink path='/' text='BLOG.KYLMAKALJA' color='#fff' iconProps={{ color: '#fff', size: 'large'}} />
                </Heading>
                <Button icon={<Notification/>} onClick={() => setShowSidebar(!showSidebar)}/>
            </AppBar>
            <Box direction='row' flex overflow={{horizontal: 'hidden'}} >
                <Page kind="narrow" >
                    <PageContent background="light-3" style={{ minHeight: '80vh'}}>
                        <Outlet />
                    </PageContent>
                </Page>
                {(!showSidebar || size !== 'small') ?
                    (<Collapsible direction='horizontal' open={showSidebar}>
                        <SideBar width='medium'>
                            <SideBarContent />
                        </SideBar>
                    </Collapsible>) :
                    (<Layer>
                        <Box background='light-2'
                             tag='header'
                             justify='end'
                             align='center'
                             direction='row' >
                            <Button
                                icon={<FormClose />}
                                onClick={() => setShowSidebar(false)}
                            />
                        </Box>
                        <SideBar width='fill' >
                            <SideBarContent />
                        </SideBar>
                    </Layer>)}
            </Box>
            <Footer background="brand" color="#fff" width="100%" pad="medium">
               <div style={{ display: 'flex', margin: '1rem 0', justifyContent: 'space-around'  }}>
                <div>blog.kylmakalja</div>
               </div>
            </Footer>
            </>)}
    </ResponsiveContext.Consumer>
    </Grommet>)
}

export default LayoutComponent;